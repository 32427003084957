import React from 'react';

class HomePage extends React.Component {

    render() {
        return (<div>
            <h1 style={{ textAlign: "center" }}>Work in Progress!</h1>
            <img src="OR-PDP.drawio.png" style={{ width: "100%" }} />
        </div>)
    }

}

export default HomePage;