import React from 'react';
import { Auth } from 'aws-amplify';
import { Button, Divider } from 'antd';

import RouteList from './RouteList';
import RouteForm from './RouteForm';
import RouteItinerary from './RouteItinerary';

class RoutesPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visiblePage: 'list',
            currentRoute: null,
            loading: false
        }
    }

    render() {
        let isList = this.state.visiblePage === 'list'
        let isForm = this.state.visiblePage === 'form'
        let isItinerary = this.state.visiblePage === 'itinerary'
        let isLoading = this.state.loading
        return (
            <div>
                <Button
                    type={isList ? "primary" : "default"}
                    onClick={() => this.setState({ visiblePage: 'list' })}>List</Button>
                <Button
                    type={isForm ? "primary" : "default"}
                    onClick={() => this.setState({ visiblePage: 'form' })}
                    disabled={isItinerary}>Form</Button>
                <Button
                    type={isItinerary ? "primary" : "default"} 
                    onClick={() => this.setState({ visiblePage: 'itinerary' })}
                    disabled={isList || isForm}>Itinerary</Button>
                <Divider />
                {isLoading ? 'Loading...' : null}
                {!isLoading && isList ?
                    <RouteList
                        onLoadForm={r => this.loadRoute(r, 'form')}
                        onLoadItinerary={r => this.loadRoute(r, 'itinerary')} /> : null}
                {!isLoading && isForm ? <RouteForm route={this.state.currentRoute} /> : null}
                {!isLoading && isItinerary ? <RouteItinerary route={this.state.currentRoute} /> : null}
            </div>
        );
    }

    loadRoute(routeSummary, visiblePage) {
        this.setState({ loading: true })
        Auth.currentSession().then(res =>
            fetch(`https://api.joaopedroschmitt.click/routes/find/${routeSummary.id}`, {
                method: 'GET',
                headers: {
                    Authorization: res.getAccessToken().getJwtToken()
                }
            })
                .then(response => response.json())
                .then(result => this.setState({ currentRoute: result, visiblePage: visiblePage, loading: false }))
                .catch(err => {
                    alert(err.message)
                    this.setState({ loading: false })
                }));
    }

}

export default RoutesPage;