import React from "react";
import { Auth } from 'aws-amplify';
import { Row, Col, Button, Input, DatePicker, Divider, InputNumber } from 'antd';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import styles from './RouteForm.css'
import AddressSearch from '../../components/AddressSearch';

class RouteForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            route: this.getClonedRoute(this.props.route)
        }
    }

    render() {
        let route = this.state.route;
        var requestSubForms = route.requests.map((req, idx) =>
            <div key={idx}>
                <Divider><h3>{`Request Number ${idx + 1}`}</h3></Divider>
                <label>
                    Demand (Kg)
                    <InputNumber style={{ width: '100%' }} value={req.demandInKg} onChange={val => this.changeRequestProp(idx, { demandInKg: val })} />
                </label>
                <div className="pickup-form">
                    <h3>Pickup</h3>
                    <label className="address-id">
                        Address ID
                        <Input disabled value={req.pickupLocationIdentifier} />
                    </label>
                    <label>
                        Address
                        <AddressSearch style={{ width: '100%' }}
                            value={req.pickupLocationDescription}
                            onChange={val => this.changeRequestProp(idx, {
                                pickupLocationIdentifier: val.value,
                                pickupLocationDescription: val.text,
                                pickupLocationLatitude: val.lat,
                                pickupLocationLongitude: val.lon
                            })} />
                    </label>
                    <label className="address-position">
                        <div>
                            Latitude
                            <InputNumber disabled style={{ width: '100%' }} value={req.pickupLocationLatitude} />
                        </div>
                        <div>
                            Longitude
                            <InputNumber disabled style={{ width: '100%' }} value={req.pickupLocationLongitude} />
                        </div>
                    </label>
                    <label className="time-windows">
                        <div>
                            Time Window Start
                            <DatePicker showTime style={{ width: '100%' }} value={req.pickupTimeWindowStart} onChange={val => this.changeRequestProp(idx, { pickupTimeWindowStart: val })} />
                        </div>
                        <div>
                            Time Window End
                            <DatePicker showTime style={{ width: '100%' }} value={req.pickupTimeWindowEnd} onChange={val => this.changeRequestProp(idx, { pickupTimeWindowEnd: val })} />
                        </div>
                    </label>
                    <label>
                        Service Time (min)
                        <InputNumber style={{ width: '100%' }} value={req.pickupServiceTimeInMinutes} onChange={val => this.changeRequestProp(idx, { pickupServiceTimeInMinutes: val })} />
                    </label>
                </div>
                <div className="delivery-form">
                    <h3>Delivery</h3>
                    <label className="address-id">
                        Address ID
                        <Input disabled value={req.deliveryLocationIdentifier} />
                    </label>
                    <label>
                        Address
                        <AddressSearch style={{ width: '100%' }}
                            value={req.deliveryLocationDescription}
                            onChange={val => this.changeRequestProp(idx, {
                                deliveryLocationIdentifier: val.value,
                                deliveryLocationDescription: val.text,
                                deliveryLocationLatitude: val.lat,
                                deliveryLocationLongitude: val.lon
                            })} />
                    </label>
                    <label className="address-position">
                        <div>
                            Latitude
                            <InputNumber disabled style={{ width: '100%' }} value={req.deliveryLocationLatitude} />
                        </div>
                        <div>
                            Longitude
                            <InputNumber disabled style={{ width: '100%' }} value={req.deliveryLocationLongitude} />
                        </div>
                    </label>
                    <label className="time-windows">
                        <div>
                            Time Window Start
                            <DatePicker showTime style={{ width: '100%' }} value={req.deliveryTimeWindowStart} onChange={val => this.changeRequestProp(idx, { deliveryTimeWindowStart: val })} />
                        </div>
                        <div>
                            Time Window End
                            <DatePicker showTime style={{ width: '100%' }} value={req.deliveryTimeWindowEnd} onChange={val => this.changeRequestProp(idx, { deliveryTimeWindowEnd: val })} />
                        </div>
                    </label>
                    <label>
                        Service Time (min)
                        <InputNumber style={{ width: '100%' }} value={req.deliveryServiceTimeInMinutes} onChange={val => this.changeRequestProp(idx, { deliveryServiceTimeInMinutes: val })} />
                    </label>
                </div>
            </div>
        )

        return (
            <div>
                <div className="route-header">
                    <Button type="primary" onClick={() => this.newRoute()}>New Route</Button>
                    <Button type="primary" onClick={() => this.saveRoute()}>Save Route</Button>
                    {this.state.route.id ? <Button type="primary" onClick={() => this.optimizeRoute()}>Generate Itinerary</Button> : null}
                    {this.state.loading ? <h3>Loading...</h3> : null}
                    {route.id ? <h4>{`Route ID: ${route.id}`}</h4> : null}
                </div>
                <form className="route-form">
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <h2 className="ant-form-text">Depot</h2>
                            <label className="address-id">
                                Address ID
                                <Input disabled value={route.depotLocationIdentifier} />
                            </label>
                            <label>
                                Address
                                <AddressSearch style={{ width: '100%' }}
                                    value={route.depotLocationDescription}
                                    onChange={address => this.changeRouteProp({
                                        depotLocationIdentifier: address.value,
                                        depotLocationDescription: address.text,
                                        depotLocationLatitude: address.lat,
                                        depotLocationLongitude: address.lon
                                    })} />
                            </label>
                            <label className="address-position">
                                <div>
                                    Latitude
                                    <InputNumber disabled style={{ width: '100%' }} value={route.depotLocationLatitude} />
                                </div>
                                <div>
                                    Longitude
                                    <InputNumber disabled style={{ width: '100%' }} value={route.depotLocationLongitude} />
                                </div>
                            </label>
                            <label className="time-windows">
                                <div>
                                    Time Window Start
                                    <DatePicker showTime style={{ width: '100%' }} value={route.depotTimeWindowStart} onChange={val => this.changeRouteProp({ depotTimeWindowStart: val })} />
                                </div>
                                <div>
                                    Time Window End
                                    <DatePicker showTime style={{ width: '100%' }} value={route.depotTimeWindowEnd} onChange={val => this.changeRouteProp({ depotTimeWindowEnd: val })} />
                                </div>
                            </label>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <h2 className="ant-form-text">Requests</h2>
                            {requestSubForms}
                            <br /><Button type="default" onClick={() => this.addRequest()}>Add new request</Button>
                        </Col>
                    </Row>
                </form>
            </div >
        );
    }

    changeRequestProp(reqIdx, changedProps) {
        let requests = [...this.state.route.requests]
        for (var propName in changedProps) {
            requests[reqIdx][propName] = changedProps[propName]
        }
        this.setState({
            route: {
                ...this.state.route,
                requests: requests
            }
        })
    }

    changeRouteProp(changedProps) {
        let route = { ...this.state.route }
        for (var propName in changedProps) {
            route[propName] = changedProps[propName]
        }
        this.setState({ route })
    }

    addRequest() {
        let route = this.state.route
        let requests = [...this.state.route.requests]
        requests.push({
            demandInKg: 5,
            pickupTimeWindowStart: moment.utc(route.depotTimeWindowStart).local(),
            pickupTimeWindowEnd: moment.utc(route.depotTimeWindowEnd).local(),
            pickupServiceTimeInMinutes: 30,
            deliveryTimeWindowStart: moment.utc(route.depotTimeWindowStart).local(),
            deliveryTimeWindowEnd: moment.utc(route.depotTimeWindowEnd).local(),
            deliveryServiceTimeInMinutes: 30
        })
        this.setState({
            route: {
                ...this.state.route,
                requests: requests
            }
        })
    }

    getClonedRoute(route) {
        let newRoute = { requests: [] }
        if (route) {
            newRoute = cloneDeep(route)
        }
        newRoute.depotTimeWindowStart = moment.utc(newRoute.depotTimeWindowStart).local()
        newRoute.depotTimeWindowEnd = moment.utc(newRoute.depotTimeWindowEnd).local()
        newRoute.requests.forEach(request => {
            request.pickupTimeWindowStart = moment.utc(request.pickupTimeWindowStart).local()
            request.pickupTimeWindowEnd = moment.utc(request.pickupTimeWindowEnd).local()
            request.deliveryTimeWindowStart = moment.utc(request.deliveryTimeWindowStart).local()
            request.deliveryTimeWindowEnd = moment.utc(request.deliveryTimeWindowEnd).local()
        })
        return newRoute;
    }

    newRoute() {
        this.setState({ route: { requests: [] } })
    }

    saveRoute() {
        this.setState({ loading: true })
        let routeDTO = cloneDeep(this.state.route)
        routeDTO.depotTimeWindowStart = routeDTO.depotTimeWindowStart.utc().format()
        routeDTO.depotTimeWindowEnd = routeDTO.depotTimeWindowEnd.utc().format()
        routeDTO.requests.forEach(requestDTO => {
            requestDTO.pickupTimeWindowStart = requestDTO.pickupTimeWindowStart.utc().format()
            requestDTO.pickupTimeWindowEnd = requestDTO.pickupTimeWindowEnd.utc().format()
            requestDTO.deliveryTimeWindowStart = requestDTO.deliveryTimeWindowStart.utc().format()
            requestDTO.deliveryTimeWindowEnd = requestDTO.deliveryTimeWindowEnd.utc().format()
        })
        Auth.currentSession().then(res =>
            fetch(`https://api.joaopedroschmitt.click/routes/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": res.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(routeDTO)
            })
                .then(respose => {
                    this.setState({ loading: false })
                    if (respose.ok) {
                        respose.json().then(route => this.setState({ route: this.getClonedRoute(route) }))
                    } else {
                        respose.text().then(error => alert(error))
                    }
                })
                .catch(err => {
                    alert(err.message)
                }));
    }

    optimizeRoute() {
        this.setState({ loading: true })
        let routeId = this.state.route.id
        Auth.currentSession().then(res =>
            fetch(`https://api.joaopedroschmitt.click/routes/optimize/${routeId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'text/plain',
                    "Authorization": res.getAccessToken().getJwtToken()
                }
            })
                .then(respose => {
                    this.setState({ loading: false })
                    respose.text().then(msgId => alert(`Itinerary message generation requested: ${msgId}`))
                })
                .catch(err => {
                    alert(err.message)
                }));
    }

}

export default RouteForm;