import React from 'react';
import { Auth } from 'aws-amplify';

import styles from './RouteList.css';
import { Button } from 'antd';

class RouteList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            errorMsg: '',
            routes: null
        }
    }

    componentDidMount() {
        this.updateRouteList();
    }

    render() {
        var content = <div>Loading...</div>
        if (this.state.routes && this.state.routes.length > 0) {
            let key = 0;
            content = (
                <ul>
                    {this.state.routes.map(route => <li key={key++}>
                        {`Depot address: ${route.depotLocation}`}<br />
                        {`Created at ${route.createdAt}`}<br />
                        {`Containing ${route.numberOfRequests} requests`}<br />
                        <Button onClick={() => this.props.onLoadForm(route)}>Load Form</Button>
                        <Button onClick={() => this.props.onLoadItinerary(route)}>Load Itinerary</Button>
                    </li>)}
                </ul>
            );
        } else if (this.state.routes && this.state.routes.length === 0) {
            content = <div>Empty</div>
        } else if (this.state.errorMsg) {
            content = <h3>{this.state.errorMsg}</h3>
        }
        return (
            <div className='route-list'>
                {content}
            </div>
        );
    }

    updateRouteList() {
        this.setState({ errorMsg: '', routes: null })
        Auth.currentSession().then(res =>
            fetch(`https://api.joaopedroschmitt.click/routes/createdby/${res.getAccessToken().payload.username}`, {
                method: 'GET',
                headers: {
                    Authorization: res.getAccessToken().getJwtToken()
                }
            })
                .then(response => response.json())
                .then(result => this.setState({ routes: result || [] }))
                .catch(err => {
                    this.setState({
                        errorMsg: `ECS instances are turned off to save money. 
                                   That's why the back-end is throwing "${err.message} error."
                                   You can still click on form to create new routes and search addresses, but you can't save them.`
                    })
                }));
    }

}

export default RouteList;