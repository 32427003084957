import React from "react";
import { Select } from 'antd';
import { Auth } from 'aws-amplify';

const { Option } = Select;

let timeout;

function fetchAddresses(value, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    function searchAddresses() {

        Auth.currentSession().then(res =>
            fetch(`https://api.joaopedroschmitt.click/addresses?q=${value}`, {
                method: 'GET',
                headers: {
                    Authorization: res.getAccessToken().getJwtToken()
                }
            })
                .then(response => response.json())
                .then(result => {
                    const data = [];
                    result.forEach(record => {
                        data.push({
                            value: record.id,
                            text: record.display_name,
                            lat: record.lat,
                            lon: record.lon
                        });
                    });
                    callback(data);
                }).catch(err => {
                    timeout = null;
                    clearTimeout(timeout);
                    alert(err.message)
                }));
    }

    timeout = setTimeout(searchAddresses, 500);
}

class AddressSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            value: props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    handleSearch = value => {
        if (value) {
            fetchAddresses(value, data => {
                this.setState({ data })
            });
        } else {
            this.setState({ data: [] });
        }
    };

    handleChange = value => {
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(this.state.data.find(el => el.value == value))
        }
    };

    render() {
        const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>);
        return (
            <Select
                showSearch
                value={this.state.value}
                placeholder={this.props.placeholder}
                style={this.props.style}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}>
                {options}
            </Select>
        );
    }
}

export default AddressSearch;