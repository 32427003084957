import React from 'react';
import L from 'leaflet';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { Auth } from 'aws-amplify';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet'

import styles from './RouteItinerary.css';
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
const depotIcon = process.env.PUBLIC_URL + '/icons/depot.svg';
const pickUpIcon = process.env.PUBLIC_URL + '/icons/pick.svg';
const dropOffIcon = process.env.PUBLIC_URL + '/icons/drop.svg';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

let DepotIcon = L.divIcon({
    html: `<img src=${depotIcon} alt='depot' />`,
    iconSize: [20, 20],
    className: 'itinerary-map-icon'
})
let PickIcon = L.divIcon({
    html: `<img src=${pickUpIcon} alt='depot' />`,
    iconSize: [20, 20],
    className: 'itinerary-map-icon'
})
let DropIcon = L.divIcon({
    html: `<img src=${dropOffIcon} alt='depot' />`,
    iconSize: [20, 20],
    className: 'itinerary-map-icon'
})

class RouteItinerary extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            route: this.getClonedRoute(this.props.route)
        }
    }

    render() {
        if (this.state.itinerary && this.state.itinerary.vehicles) {
            return (
                <div>
                    {this.getItineraryTable()}
                    {this.getInstructionsInfo()}
                    <MapContainer bounds={this.getLatLngBounds()} className="itinerary-map-container">
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {this.getMarkers()}
                        {this.getRouteLines()}
                    </MapContainer>
                </div>
            )
        } else {
            return (<div>{this.getInstructionsInfo()}</div>)
        }
    }

    getInstructionsInfo() {
        if (this.state.loading) {
            return <h3>Loading...</h3>
        } else if (this.state.itinerary) {
            return <h3>Click on map markers to see the address</h3>
        } else {
            return <h3>Itinerary was not generated yet</h3>
        }
    }

    getItineraryTable() {
        // Draw route
        var itineraryTable = null;
        const visits = []
        this.state.itinerary.vehicles.forEach((vehicle, idx) => {
            visits.push(<tr key={`vehicle-${idx}-${i}`} style={{ marginLeft: "10px" }}>
                <td>{`Vehicle ${idx + 1} (${vehicle.type.join(',')})`}</td>
            </tr>)
            for (var i = 0; i < vehicle.tasks.length - 1; i++) {
                var next = vehicle.tasks[i + 1]
                visits.push(<tr key={`vehicle-${idx}-${i}`} style={{ marginLeft: "10px" }}>
                    <td style={{ paddingLeft: "25px" }}>{"#" + next.index}</td>
                    <td>{this.getTime(next.arrivalTime)}</td>
                    <td>{next.waitTimeInMinutes}</td>
                    <td>{this.getTime(next.startTime)}</td>
                    <td>{next.serviceTimeInMinutes}</td>
                    <td>{this.getTime(next.departureTime)}</td>
                </tr>)
            }
        })
        itineraryTable = (<div className='itinerary-table'>
            <table>
                <tr>
                    <th>VISIT SEQUENCE</th>
                    <th>ARRIVAL TIME</th>
                    <th>WAIT TIME (min)</th>
                    <th>START TIME</th>
                    <th>SERVICE TIME (min)</th>
                    <th>DEPARTURE TIME</th>
                </tr>
                {visits}
            </table>
        </div>)
        return itineraryTable
    }

    getRouteLines() {
        const lines = []
        var colors = ['red', 'black', 'blue', 'green', 'purple']
        const visits = []
        this.state.itinerary.vehicles.forEach((vehicle, idx) => {
            var color = colors[idx % colors.length];
            //visits.push(<div key={`vehicle-${idx}`}>{`Vehicle ${idx} ; ${vehicle.type.join(',')}`}</div>)
            for (var i = 0; i < vehicle.tasks.length - 1; i++) {
                var curr = vehicle.tasks[i]
                var next = vehicle.tasks[i + 1]
                lines.push(<Polyline key={"line" + idx + "-" + i} positions={[
                    [curr.locationLatitude, curr.locationLongitude],
                    [next.locationLatitude, next.locationLongitude],]} color={color} />)
                if (next.locationType != "DEPOT") {
                    const text = L.divIcon({
                        html: `<b>#${next.index}</b>`,
                        iconSize: [20, 20],
                        className: 'itinerary-map-itinerary-icon'
                    })
                    lines.push(
                        <Marker key={`mark-${idx}-${i}`} position={[next.locationLatitude, next.locationLongitude]} icon={text}>
                            <Popup>{next.locationDescription}</Popup>
                        </Marker>)
                }
            }
        })
        return lines
    }

    getMarkers() {
        let route = this.state.route;
        const markers = []
        markers.push(
            <Marker key={"depot"} position={{ lat: route.depotLocationLatitude, lng: route.depotLocationLongitude }} icon={DepotIcon}>
                <Popup>{route.depotLocationDescription}</Popup>
            </Marker>
        )
        route.requests.forEach(req => markers.push(
            <Marker key={"pickup" + req.index} position={{ lat: req.pickupLocationLatitude, lng: req.pickupLocationLongitude }} icon={PickIcon}>
                <Popup>{req.pickupLocationDescription}</Popup>
            </Marker>
        ))
        route.requests.forEach(req => markers.push(
            <Marker key={"delivery" + req.index} position={{ lat: req.deliveryLocationLatitude, lng: req.deliveryLocationLongitude }} icon={DropIcon}>
                <Popup>{req.deliveryLocationDescription}</Popup>
            </Marker>
        ))
        return markers
    }

    getTime(timestamp) {
        var date = new Date(timestamp)
        return (date.getHours() + "").padStart(2, '0') + ":" + (date.getMinutes() + "").padStart(2, '0');
    }

    getLatLngBounds() {
        let minLng = this.state.route.depotLocationLongitude
        let minLat = this.state.route.depotLocationLatitude;
        let maxLng = this.state.route.depotLocationLongitude
        let maxLat = this.state.route.depotLocationLatitude;
        this.state.route.requests.forEach(request => {
            minLat = Math.min(minLat, request.pickupLocationLatitude)
            maxLat = Math.max(maxLat, request.pickupLocationLatitude)
            minLng = Math.min(minLng, request.pickupLocationLongitude)
            maxLng = Math.max(maxLng, request.pickupLocationLongitude)

            minLat = Math.min(minLat, request.deliveryLocationLatitude)
            maxLat = Math.max(maxLat, request.deliveryLocationLatitude)
            minLng = Math.min(minLng, request.deliveryLocationLongitude)
            maxLng = Math.max(maxLng, request.deliveryLocationLongitude)
        })
        return [[maxLat, maxLng], [minLat, minLng]]
    }

    getClonedRoute(route) {
        let newRoute = { requests: [] }
        if (route) {
            newRoute = cloneDeep(route)
        }
        newRoute.depotTimeWindowStart = moment.utc(newRoute.depotTimeWindowStart).local()
        newRoute.depotTimeWindowEnd = moment.utc(newRoute.depotTimeWindowEnd).local()
        newRoute.requests.forEach(request => {
            request.pickupTimeWindowStart = moment.utc(request.pickupTimeWindowStart).local()
            request.pickupTimeWindowEnd = moment.utc(request.pickupTimeWindowEnd).local()
            request.deliveryTimeWindowStart = moment.utc(request.deliveryTimeWindowStart).local()
            request.deliveryTimeWindowEnd = moment.utc(request.deliveryTimeWindowEnd).local()
        })
        return newRoute;
    }

    componentDidMount() {
        if (this.state.route?.id) {
            this.loadItinerary();
        }
    }

    loadItinerary() {
        this.setState({ loading: true })
        Auth.currentSession().then(res =>
            fetch(`https://api.joaopedroschmitt.click/routes/itinerary/${this.state.route.id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": res.getAccessToken().getJwtToken()
                }
            })
                .then(response => {
                    console.info(response.status)
                    this.setState({ loading: false })
                    if (response.status == 200) {
                        response.json().then(itinerary => this.setState({ itinerary }))
                    } else if (response.status == 204) {
                        this.setState({ itinerary: null })
                    }
                })
                .catch(err => {
                    alert(err.message)
                    this.setState({ loading: false })
                }));
    }

}

export default RouteItinerary;